import React from "react";
import { Button } from "@/components/LandingPage/Common/Button";
import { handleGetStartedClick } from "lib/getStartedUserClick";
import { useRouter } from "next/router";
import { useTranslation } from "react-i18next";
import { Image_gen_footer } from "modules/FeaturesModule/Component/ImageGenFooter-1";

const Unleash = () => {
  const router = useRouter();
  const { t } = useTranslation("translation");

  const handleStartClick = () => {
    handleGetStartedClick(router);
  };

  return (
    <section className="py-20  bg-lightGray-50 dark:bg-[#1F2329]">
      <div className="container">
        <p className="text-base font-mont mb-6 text-center text-gray-50 dark:text-gray-500">
          {t("getStart_loki")}
        </p>
        <div className="max-w-[850px] mx-auto">
          <h6 className="step-3 leading-[1.1] text-center mb-1 !font-mont">
            {t("power_knowledge")}
          </h6>
          <span className="text-gray-50 dark:text-gray-500 text-base text-center block font-mont">
            {t("power_desc")}
          </span>
        </div>
        <div className="flex justify-center items-center mt-5">
          <Button
            size="lg"
            onClick={handleStartClick}
            className="cursor-pointer font-mont"
          >
            {t("guest_sub")}
          </Button>
        </div>
        <Image_gen_footer
        img1={
          "https://storage.googleapis.com/lokichat-profile2/Image_Landing_compress/21.webp"
        }
        img2={
          "https://storage.googleapis.com/lokichat-profile2/TTS_Landing_pages/12.webp"
        }
        img3={
          "https://storage.googleapis.com/lokichat-profile/Document%20translation%20top%20video.png"
        }
      />
      </div>
    </section>
  );
};

export default Unleash;
