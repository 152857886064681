import React from "react";
import { useTranslation } from "react-i18next";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useMediaQuery } from "react-responsive";

const WhyLoki = () => {
  const { t } = useTranslation("translation");
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 1024 },
      items: 3,
    },
    desktop: {
      breakpoint: { max: 1024, min: 768 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 768, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };
  const DATA = [
    {
      title: t("image_generator"),
      desc: t("image_generator_desc"),
    },
    {
      title: t("text_speech"),
      desc: t("text_speech_desc"),
    },
    {
      title: t("doc_tranlator"),
      desc: t("doc_tranlator_desc"),
    },
    {
      title: t("ai_transcription"),
      desc: t("ai_transcription_desc"),
    },
    {
      title: t("ai_chat_doc"),
      desc: t("ai_chat_doc_desc"),
    },
    {
      title: t("ai_debate"),
      desc: t("ai_debate_desc"),
    },
    {
      title: t("ai_prompt_adv"),
      desc: t("ai_prompt_adv_desc"),
    },
    {
      title: t("ai_document_summary"),
      desc: t("ai_document_summary_desc"),
    },
    {
      title: t("ai_copywriter"),
      desc: t("ai_copywriter_desc"),
    },
  ];

  return (
    <div className="mb-24 flex flex-col">
      <div className="text-center pb-15 text-4xl font-extrabold">
        {t("why_loki")}
      </div>
      <div className="text-center pb-15 text-lg  w-[70%] self-center text:[#777E90] dark:text-[#D4DAE9]">
        {t("why_loki_desc")}
      </div>
      <div className="h-full sm:h-64 xl:h-full 2xl:h-96  pl-10 ">
        <Carousel
          responsive={responsive}
          transitionDuration={500}
          slidesToSlide={isMobile ? 1 : 3}
          showDots
          dotListClass="custom-dot-list-style"
          // arrows={false}
        >
          {DATA.map((item, index) => (
            <div
              key={index}
              className="relative flex items-center justify-center p-2 h-full"
            >
              <div className="w-[500px] h-[300px] rounded-xl bg-cover bg-center transition-transform duration-500 ease-in-out overflow-auto bg-[#6C7275] p-5 mb-10">
                <div className="text-white text-2xl font-bold text-left mt-5 mb-8">
                  {item.title}
                </div>
                <div className="text-white text-lg font-semibold text-left mb-8">
                  {item.desc}
                </div>
              </div>
            </div>
          ))}
        </Carousel>
      </div>
    </div>
  );
};

export default WhyLoki;
