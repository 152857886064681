import React, { useState } from "react";

import { motion, AnimatePresence } from "framer-motion";
import LokiTabsComponent from "./LokiTabsComponent";
import { useTranslation } from "react-i18next";
import Modal from "@/components/Modal";
import ViewDemoModal from "@/components/Modal/ViewDemoModal";
import SummarizeTranslateModal from "@/components/Modal/SummarizeTranslateModal";
import CollaborativeDebateModal from "@/components/Modal/CollaborativeDebateModal";
import TextToSpeechModal from "@/components/Modal/TextToSpeechModal";

const LokiTabs = ({ title, description, text, fromHome }: any) => {
  const { t } = useTranslation("translation");

  const [selectedTabIdx, setSelectedTabIdx] = useState(0);
  const [visibleSummarizeModal, setVisibleSummarizeModal] =
    useState<boolean>(false);
  const [visibleAskModal, setVisibleAskModal] = useState<boolean>(false);
  const [visibleTextModal, setVisibleTextModal] = useState<boolean>(false);
  const [visibleCollaborateModal, setVisibleCollaborateModal] =
    useState<boolean>(false);

  const tabs = [
    { name: t("Ai_tools"), current: true, desc: t("Ai_tools_des") },
    { name: t("up_doc"), current: false, desc: t("up_doc_desc") },
    { name: t("get_result"), current: false, desc: t("get_result_desc") },
  ];

  return (
    <section className="lmd:py-20 py-10">
      <div className="container">
        <div className="max-w-[750px] mx-auto">
          <h2 className="text-center step-2 leading-snug !font-mont !font-extrabold">
            {title}
          </h2>
          <p className="text-base text-center text-black dark:text-gray-700 mb-6 font-mont">{`${
            text ? t("container_heading") : ""
          }`}</p>
          <span className="text-center text-gray-50 dark:text-gray-500 !font-mont text-base block">
            {description}
          </span>
        </div>

        <nav
          className=" grid lsm:grid-cols-3 grid-cols-3 items-start lmd:gap-8 gap-4 llg:w-full"
          aria-label="Tabs"
        >
          {tabs &&
            tabs.map((tab, i) => (
              <div
                key={tab.name}
                className={`${
                  i === selectedTabIdx
                    ? "dark:text-white text-black focus:outline-none border-primary-1"
                    : "border-gray-800 hover:border-primary-1"
                } lmd:pt-8 pt-4 whitespace-nowrap cursor-pointer font-medium border-t-2 w-full`}
                onClick={() => setSelectedTabIdx(i)}
              >
                <p className="!font-mont break-words leading-tight whitespace-normal llg:text-[22px] text-base !font-extrabold">
                  {tab.name}
                </p>
                <p className="max-w-[200px] pt-1 !font-mont break-words leading-tight whitespace-normal llg:text-[10px] text-base !font-medium">
                  {tab.desc}
                </p>
              </div>
            ))}
        </nav>
        {!fromHome && <div className="pt-12 pb-20">
          <AnimatePresence mode="wait">
            <motion.div
              key={selectedTabIdx}
              initial={{ y: 10, opacity: 0 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: -10, opacity: 0 }}
              transition={{ duration: 0.2 }}
            >
              {selectedTabIdx === 0 && (
                <LokiTabsComponent
                  img={
                    "https://lokichatdev.blob.core.windows.net/devloki/loki-images/slide-1.webp"
                  }
                  title={t("ask_anything")}
                  description={t("ask_anythingDesc")}
                  setVisibleModal={setVisibleAskModal}
                />
              )}
              {selectedTabIdx === 1 && (
                <LokiTabsComponent
                  img={
                    "https://lokichatdev.blob.core.windows.net/devloki/loki-images/slide-2.webp"
                  }
                  title={t("summarize_translate")}
                  description={t("summarize_tranDesc")}
                  setVisibleModal={setVisibleSummarizeModal}
                />
              )}
              {selectedTabIdx === 2 && (
                <LokiTabsComponent
                  img={
                    "https://lokichatdev.blob.core.windows.net/devloki/loki-images/slide-3.webp"
                  }
                  title={t("audio_pdfConvert")}
                  description={t("audio_pdfDesc")}
                  setVisibleModal={setVisibleTextModal}
                />
              )}
              {selectedTabIdx === 3 && (
                <LokiTabsComponent
                  img={
                    "https://lokichatdev.blob.core.windows.net/devloki/loki-images/slide-4.webp"
                  }
                  title={t("share_collaborate")}
                  description={t("share_collabDesc")}
                  setVisibleModal={setVisibleCollaborateModal}
                />
              )}
            </motion.div>
          </AnimatePresence>
        </div>}
      </div>

      <Modal
        className="!p-0 !bg-transparent dark:!bg-transparent"
        classWrap="max-w-[85rem] !h-auto !p-0 !bg-transparent dark:!bg-transparent !rounded-lg"
        classButtonClose="absolute top-6 right-6 w-10 h-10 rounded-full bg-n-2 md:right-5 dark:bg-n-4/25 dark:fill-n-4 dark:hover:fill-n-1"
        visible={visibleSummarizeModal}
        onClose={() => setVisibleSummarizeModal(false)}
        video
      >
        <SummarizeTranslateModal
          onCancel={() => setVisibleSummarizeModal(false)}
        />
      </Modal>
      <Modal
        className="!p-0 !bg-transparent dark:!bg-transparent"
        classWrap="max-w-[85rem] !h-auto !p-0 !bg-transparent dark:!bg-transparent !rounded-lg"
        classButtonClose="absolute top-6 right-6 w-10 h-10 rounded-full bg-n-2 md:right-5 dark:bg-n-4/25 dark:fill-n-4 dark:hover:fill-n-1"
        visible={visibleAskModal}
        onClose={() => setVisibleAskModal(false)}
        video
      >
        <ViewDemoModal onCancel={() => setVisibleAskModal(false)} />
      </Modal>
      <Modal
        className="!p-0 !bg-transparent dark:!bg-transparent"
        classWrap="max-w-[85rem] !h-auto !p-0 !bg-transparent dark:!bg-transparent !rounded-lg"
        classButtonClose="absolute top-6 right-6 w-10 h-10 rounded-full bg-n-2 md:right-5 dark:bg-n-4/25 dark:fill-n-4 dark:hover:fill-n-1"
        visible={visibleTextModal}
        onClose={() => setVisibleTextModal(false)}
        video
      >
        <TextToSpeechModal onCancel={() => setVisibleTextModal(false)} />
      </Modal>
      <Modal
        className="!p-0 !bg-transparent dark:!bg-transparent"
        classWrap="max-w-[85rem] !h-auto !p-0 !bg-transparent dark:!bg-transparent !rounded-lg"
        classButtonClose="absolute top-6 right-6 w-10 h-10 rounded-full bg-n-2 md:right-5 dark:bg-n-4/25 dark:fill-n-4 dark:hover:fill-n-1"
        visible={visibleCollaborateModal}
        onClose={() => setVisibleCollaborateModal(false)}
        video
      >
        <CollaborativeDebateModal
          onCancel={() => setVisibleCollaborateModal(false)}
        />
      </Modal>
    </section>
  );
};
export default LokiTabs;
