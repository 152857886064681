import Modal from '@/components/Modal';
import DiscoverModal from '@/components/Modal/DiscoverModal';
import RefferalProgramModal from '@/components/Modal/RefferalProgramModal';
import SummarizeTranslateModal from '@/components/Modal/SummarizeTranslateModal';
import Image from 'next/legacy/image';
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next';

const EmpowerProductivity = () => {
  const { t } = useTranslation('translation');
  const [visibleModal, setVisibleModal] = useState<boolean>(false);
  const [visibleTranslate, setVisibleTranslate] = useState<boolean>(false);
  const [visibleRefferal, setVisibleRefferal] = useState<boolean>(false);


  return (
    <section className="">
       <div className='llg:mt-16 mt-8 grid llg:grid-cols-2 gap-7 items-start'>
        <figure className="relative flex p-8 flex-col justify-center items-center w-full llg:h-[34.313rem] h-[16.438rem] rounded-xl overflow-hidden">
          <Image
            src="https://lokichatdev.blob.core.windows.net/devloki/landing-page/discover.webp"
            alt="Background"
            layout="fill"
            objectFit="cover"
            className="rounded-xl"
            placeholder="blur"
            blurDataURL={`https://lokichatdev.blob.core.windows.net/devloki/landing-page/discover.webp`}
          />
          <div className='ImgOpacity rounded-xl'></div>
          <figure className='scale-100 hover:scale-110 duration-300 cursor-pointer'>
          <Image
            src="https://lokichatdev.blob.core.windows.net/images/loki/play-btn.png"
            alt="Play button"
            width={130}
            height={130}
            onClick={() => setVisibleModal(true)}
            placeholder="blur"
            blurDataURL={`https://lokichatdev.blob.core.windows.net/images/loki/play-btn.png`}
           
          />
          </figure>
          <h6 className='lmd:h3 h5 sm:text-2xl relative text-center lmd:px-10 z-50 !font-mont font-extrabold text-white'>{t('home_title1')}</h6>
        </figure>

        <div className='grid llg:grid-cols-1 lmd:grid-cols-2 gap-7'>
          <figure className="relative flex p-8 justify-center items-center w-full h-[16.438rem] rounded-xl overflow-hidden">
            <Image
              src="https://lokichatdev.blob.core.windows.net/devloki/landing-page/translate.webp"
              alt="Background"
              layout="fill"
              objectFit="cover"
              className="rounded-xl"
              placeholder="blur"
              blurDataURL={`https://lokichatdev.blob.core.windows.net/devloki/landing-page/translate.webp`}
            />
            <div className='w-full flex items-center llg:gap-10 gap-5 llg:flex-row flex-col relative'>
              <figure className='scale-100 hover:scale-110 duration-300 cursor-pointer'>
              <Image
                src="https://lokichatdev.blob.core.windows.net/images/loki/play-btn.png"
                alt="Play button"
                width={130}
                height={130}
                onClick={() => setVisibleTranslate(true)}
                placeholder="blur"
                blurDataURL={`https://lokichatdev.blob.core.windows.net/images/loki/play-btn.png`}
                
              />
              </figure>
              <h6 className='h5 sm:text-2xl !font-mont font-extrabold text-white llg:text-start text-center lmd:max-w-[330px]'>{t('home_title2')}</h6>
            </div>
          </figure>

          <figure className="relative flex p-8 justify-center items-center w-full h-[16.438rem] rounded-xl overflow-hidden">
            <Image
              src="https://lokichatdev.blob.core.windows.net/devloki/landing-page/influencer.webp"
              alt="Background"
              layout="fill"
              objectFit="cover"
              className="rounded-xl"
              placeholder="blur"
              blurDataURL={`https://lokichatdev.blob.core.windows.net/devloki/landing-page/influencer.webp`}
            />
            <div className='w-full flex items-center llg:gap-10 gap-5 llg:flex-row flex-col relative'>
              <figure className='scale-100 hover:scale-110 duration-300 cursor-pointer'>
              <Image
                src="https://lokichatdev.blob.core.windows.net/images/loki/play-btn.png"
                alt="Play button"
                width={130}
                height={130}
                onClick={() => setVisibleRefferal(true)}
                placeholder="blur"
                blurDataURL={`https://lokichatdev.blob.core.windows.net/images/loki/play-btn.png`}
              />
              </figure>
              <h6 className='h5 sm:text-2xl !font-mont font-extrabold text-white llg:text-start text-center lmd:max-w-[330px]'>{t('home_title3')}</h6>
            </div>
          </figure>

        </div>

      </div>
      {/* </div> */}

      <Modal
        className="!p-0 !bg-transparent dark:!bg-transparent"
        classWrap="max-w-[85rem] !h-auto !p-0 !bg-transparent dark:!bg-transparent !rounded-lg"
        classButtonClose="absolute top-6 right-6 w-10 h-10 rounded-full bg-n-2 md:right-5 dark:bg-n-4/25 dark:fill-n-4 dark:hover:fill-n-1"
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
        video
      >
        <DiscoverModal onCancel={() => setVisibleModal(false)} />
      </Modal>

      <Modal
        className="!p-0 !bg-transparent dark:!bg-transparent"
        classWrap="max-w-[85rem] !h-auto !p-0 !bg-transparent dark:!bg-transparent !rounded-lg"
        classButtonClose="absolute top-6 right-6 w-10 h-10 rounded-full bg-n-2 md:right-5 dark:bg-n-4/25 dark:fill-n-4 dark:hover:fill-n-1"
        visible={visibleTranslate}
        onClose={() => setVisibleTranslate(false)}
        video
      >
        <SummarizeTranslateModal onCancel={() => setVisibleTranslate(false)} />
      </Modal>

      <Modal
        className="!p-0 !bg-transparent dark:!bg-transparent"
        classWrap="max-w-[85rem] !h-auto !p-0 !bg-transparent dark:!bg-transparent !rounded-lg"
        classButtonClose="absolute top-6 right-6 w-10 h-10 rounded-full bg-n-2 md:right-5 dark:bg-n-4/25 dark:fill-n-4 dark:hover:fill-n-1"
        visible={visibleRefferal}
        onClose={() => setVisibleRefferal(false)}
        video
      >
        <RefferalProgramModal onCancel={() => setVisibleRefferal(false)} />
      </Modal>
    </section>
  )
}

export default EmpowerProductivity