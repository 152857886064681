import React from 'react'

const PlayButton = ({onClick}:any) => {
  return (
    <div className="PlayBtn">
        <a className="at-playicon at-playiconanimation circle cursor-pointer" onClick={onClick}>
            <img src="https://lokichatdev.blob.core.windows.net/images/loki/play-btn.png" alt="Play Icon" data-bs-toggle="modal" data-bs-target="#exampleModal"/>
        </a>
   </div>
  )
}

export default PlayButton