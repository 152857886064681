import React from 'react'
import { useTranslation } from 'react-i18next'



const ProPlan = () => {
    const { t } = useTranslation('translation')
    const data=[
        {
            price:"400",
            des:t('minutes_landing')
        },
        {
            price:"200",
            des:t('images_landing')
        },
        {
            price:"180",
            des:t('audio_landing')
        },
        {
            price:"200",
            des:t('translations_landing')
        },
    ]
  return (
    <section className="lmd:py-20 py-10 bg-lightGray-50 dark:bg-[#1F2328]">
       <div className='container'>
         <h3  className={`h2 mb-8  text-center font-mont !font-extrabold`}>{t('proPlan_maximizing')}</h3>
         <p className='max-w-[970px] m-auto text-center text-base text-gray-50 dark:text-gray-500 font-mont'>{t('landing_Plan_des')}</p>
            <div className='lmd:py-14 py-7 lmd:px-0 px-5 lmd:mt-14 mt-7 rounded-3xl bg-lightGray-50 dark:bg-[#272B30] grid lmd:grid-cols-4 grid-cols-2 sm:grid-cols-1 lmd:gap-0 gap-5'>
                {
                    data.map((item,i)=>(
                        <div className={`${i==3 ?"lmd:border-transparent lmd:dark:border-transparent border-n-3 dark:border-[#474C52]":"border-n-3 dark:border-[#474C52]"} lmd:border-r lmd:border-0 border lmd:rounded-none rounded-md lxl:px-7 px-3 lmd:py-0 py-3`}>
                            <h4 className='h2 mb-2 text-center font-mont !font-extrabold'>{item.price}</h4>
                            <p className='text-center lxl:text-xl llg:text-lg text-base text-gray-50 dark:text-gray-500 font-mont'>{item.des}</p>
                        </div>

                    ))
                }
                
            </div>

       </div>
    </section>
  )
}

export default ProPlan