import i18next from 'i18next';
import React, { useEffect, useState } from 'react'
type CollaborativeDebateModal = {
    onCancel?: () => void;
  };
  
  const CollaborativeDebateModal = ({onCancel}:CollaborativeDebateModal) => {

  const [video, setVideo] = useState<string>("");

  useEffect(() => {
    if (i18next.language === "es") {
      setVideo(
        "https://player.vimeo.com/video/934998060?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
      );
    } else {
      setVideo(
        "https://player.vimeo.com/video/889943418?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
      );
    }
  }, []);
  
  return (
    <div
    className="rounded w-full h-full"
    style={{padding:"0 0 0 0",position:"relative"}}> 
   <iframe src={video}
    frameBorder="0" 
    className="rounded"
    allow="autoplay;fullscreen; picture-in-picture"
   //  style="position:absolute;top:0;left:0;width:100%;height:100%;"
     style={{position:"absolute",top:"0",left:"0",width:"100%",height:"100%"}}
    title="Loki Chat - FAQ 1 Eng">
    </iframe>
    </div>
  )
}

export default CollaborativeDebateModal