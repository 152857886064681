import React, { useState } from "react";
import { Button } from "../Button";
import Modal from "@/components/Modal";
import ViewDemoModal from "@/components/Modal/ViewDemoModal";
import { useTranslation } from "react-i18next";
import EmpowerProductivity from "../../../../modules/HomeModule/Component/EmpowerProductivity";
import { handleGetStartedClick } from "lib/getStartedUserClick";
import { useRouter } from "next/router";

const Banner = () => {
  const router = useRouter();
  const { t } = useTranslation("translation");

  const [visibleModal, setVisibleModal] = useState<boolean>(false);

  const handleStartClick = () => {
    handleGetStartedClick(router);
  };

  return (
    <section className="Banner" id="Banner">
      <div className="container llg:py-[72px] py-8">
        <div className="flex flex-col justify-center items-center">
          <h1 className="mb-6 text-center leading-[1.2] text-[44px] md:text-[28px] !font-mont font-extrabold">
            {t("landing_Title")}
          </h1>
          <p className="text-gray-50 max-w-[800px] dark:text-gray-500 lsm:text-base text-sm font-mont font-normal mb-6 text-center">
            {t("landing_Para")}
          </p>
          <div className="flex items-center gap-7">
            <Button
              size="lg"
              onClick={handleStartClick}
              className="cursor-pointer font-mont"
            >
              {t("coming_soon")}
            </Button>
          </div>
        </div>
        <EmpowerProductivity />
      </div>
      <Modal
        className="!p-0 !bg-transparent dark:!bg-transparent"
        classWrap="max-w-[85rem] !h-auto !p-0 !bg-transparent dark:!bg-transparent !rounded-lg"
        classButtonClose="absolute top-6 right-6 w-10 h-10 rounded-full bg-n-2 md:right-5 dark:bg-n-4/25 dark:fill-n-4 dark:hover:fill-n-1"
        visible={visibleModal}
        onClose={() => setVisibleModal(false)}
        video
      >
        <ViewDemoModal onCancel={() => setVisibleModal(false)} />
      </Modal>
    </section>
  );
};

export default Banner;
