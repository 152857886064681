import React from "react";
import { Button } from "../../Button";
import Image from "next/legacy/image";
import { useTranslation } from "react-i18next";
import PlayButton from "@/components/PlayButton";
import { useRouter } from "next/router";
import { handleGetStartedClick } from "lib/getStartedUserClick";

type LokiTabsComponentProps = {
  img?: any;
  title?: any;
  description?: any;
  setVisibleModal?: any;
};

const LokiTabsComponent = ({
  img,
  title,
  description,
  setVisibleModal,
}: LokiTabsComponentProps) => {
  const router = useRouter();
  const { t } = useTranslation("translation");

  const handleStartClick = () => {
    handleGetStartedClick(router);
  };

  return (
    <div className="container flex llg:flex-row flex-col items-center justify-between lmd:gap-16 gap-8">
      <figure
        className={`flex justify-center max-w-[630px] lmd:h-[480px] rounded-3xl overflow-hidden`}
      >
        <Image
          // @ts-ignore
          src={img}
          width={780}
          height={480}
          placeholder="blur"
          blurDataURL={img}
          className="w-full h-full rounded-2xl object-cover"
          alt="banner"
        />
      </figure>
      <div className="flex flex-col lmd:items-start items-center max-w-[423px]">
        <h3 className="step-3 lmd:mb-8 mb-4 leading-10 lmd:text-start text-center !font-mont font-extrabold">
          {title}
        </h3>
        <p className="text-gray-50 dark:text-gray-500 text-base font-mont font-normal mb-8 lmd:text-start text-center">
          {description}
        </p>
        <div className="flex items-center gap-7">
          <Button
            size="lg"
            onClick={handleStartClick}
            className="cursor-pointer font-mont"
          >
            {t("coming_soon")}
          </Button>
          <PlayButton
            onClick={() => {
              setVisibleModal(true);
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default LokiTabsComponent;
